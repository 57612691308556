<template>
  <div class="auth-container">
    <div class="card mb-4 shadow-sm">
      <div class="card-body">
        <h2>Signin</h2>
        <form @submit.prevent="signin">
          <input v-model="email" type="email" placeholder="Email" class="form-control mb-2" />
          <input v-model="password" type="password" placeholder="Password" class="form-control mb-2" />
          <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="loading"> Signing in...</span>
            <span v-else>Signin</span>
          </button>
        </form>
        <div class="mt-3 text-center">
          <router-link to="/signup">Create an account</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'SigninPage',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    async signin() {
      this.loading = true;
      const authStore = useAuthStore();
      const toast = useToast();
      try {
        const response = await this.$axios.post('/users/sign_in', {
          email: this.email,
          password: this.password,
        });
        const token = response.data.token;
        authStore.setAuthToken(token);
        toast.success('Signin successful', { position: 'top-right' });
        this.$router.push('/dashboard');
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data.error) {
          toast.error(error.response.data.error, { position: 'top-right' });
        } else {
          toast.error('Sign In failed', { position: 'top-right' }    );
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.card-body {
  padding: 1.5rem;
}

.spinner-border {
  margin-right: 0.5rem;
}
</style>
