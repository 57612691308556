<template>
  <div class="add-location">
    <header class="header">
      <h1>Add New Location</h1>
    </header>
    <form @submit.prevent="addLocation">
      <div class="form-group">
        <label for="name">Location Name:</label>
        <input v-model="newLocation.name" type="text" id="name" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="coordinates">Coordinates:</label>
        <input v-model="newLocation.coordinates" type="text" id="coordinates" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="radius">Radius:</label>
        <input v-model="newLocation.radius" type="number" id="radius" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="image_url">Image URL:</label>
        <input v-model="newLocation.image_url" type="text" id="image_url" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-primary">Add Location</button>
    </form>
    <div class="existing-locations">
      <h2>Existing Locations</h2>
      <ul>
        <li v-for="location in locations" :key="location.id">
          <strong>{{ location.name }}</strong>: {{ location.coordinates }}, Radius: {{ location.radius }}, Image URL: {{ location.image_url }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'AddLocation',
  data() {
    return {
      newLocation: {
        name: '',
        coordinates: '',
        radius: '',
        image_url: ''
      },
      locations: []
    };
  },
  methods: {
    async fetchLocations() {
      try {
        const response = await axios.get('/locations');
        this.locations = response.data;
      } catch (error) {
        console.error('There was an error fetching locations:', error);
      }
    },
    async addLocation() {
      const toast = useToast();
      try {
         await axios.post('/locations', {
          location: this.newLocation
        });
        toast.success('Location added successfully!');
        this.newLocation = {
          name: '',
          coordinates: '',
          radius: '',
          image_url: ''
        };
        this.fetchLocations(); // Refresh the locations list
      } catch (error) {
        toast.error('There was an error adding the location.');
        console.error('There was an error adding the location:', error);
      }
    }
  },
  mounted() {
    this.fetchLocations();
  }
};
</script>

<style>
.add-location {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.existing-locations {
  margin-top: 30px;
}

.existing-locations h2 {
  text-align: center;
  margin-bottom: 20px;
}

.existing-locations ul {
  list-style-type: none;
  padding: 0;
}

.existing-locations li {
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  width: 100%;
}
</style>
