import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authToken: localStorage.getItem('token') || '',
  }),
  actions: {
    setAuthToken(token) {
      this.authToken = token;
      localStorage.setItem('token', token);
    },
    clearAuthToken() {
      this.authToken = '';
      localStorage.removeItem('token');
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.authToken,
  },
});
