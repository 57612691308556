import axios from 'axios';
import { useAuthStore } from './store/auth';

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3000/api'
});

axiosInstance.interceptors.request.use(config => {
  const authStore = useAuthStore();
  const token = authStore.authToken;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
