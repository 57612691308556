import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import 'bootstrap/dist/css/bootstrap.css';
import 'vue-toast-notification/dist/theme-sugar.css';  // Import toast notification CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import VueToast from 'vue-toast-notification';
import axios from './axios';

const app = createApp(App);

// Create Pinia instance
const pinia = createPinia();

// Configure Axios globally
app.config.globalProperties.$axios = axios;

// Use VueToast
app.use(VueToast);

app.use(router);
app.use(pinia);
app.mount('#app');
