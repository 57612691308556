<template>
  <div class="dashboard">
    <header class="dashboard-header">
      <h1>Rain Alert System Dashboard</h1>
    </header>
    
    <div class="dashboard-content">
      <div class="set-alert card">
        <h2>Set New Alert</h2>
        <form @submit.prevent="setNewAlert">
          <div class="form-group">
            <label for="user_name">Name:</label>
            <input v-model="newAlert.userName" type="text" id="user_name" placeholder="Name" class="form-control mb-2" required />
          </div>

          <div class="form-group">
            <label for="location">Location:</label>
            <select v-model="newAlert.location" id="location" class="form-control mb-2" required>
              <option v-for="location in locations" :key="location.id" :value="location">{{ location.name }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="mobile">Mobile Number:</label>
            <input v-model="newAlert.mobile" type="text" id="mobile" placeholder="Mobile Number" class="form-control mb-2" required pattern="\d{10}" title="Please enter a valid 10-digit mobile number" />
          </div>

          <div class="form-group">
            <label for="notification">Notification Type:</label>
            <select v-model="newAlert.notificationType" id="notification" class="form-control mb-2" required>
              <option value="Call" selected>Call</option>
              <option value="SMS" disabled>SMS</option>
              <option value="Whatsapp">Whatsapp</option>
            </select>
          </div>

          <button type="submit" class="btn btn-primary">Set Alert</button>
        </form>
      </div>

<!-- Your Alerts Section -->
      <div class="user-alerts card" style="width: max-content">
        <h2>Your Alerts</h2>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Location</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Type</th>
              <th>Notified</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="alert in alerts" :key="alert.id">
              <td>{{ alert.location.name }}</td>
              <td>{{ alert.user_name }}</td>
              <td>{{ alert.mobile }}</td>
              <td>{{ alert.notification_type }}</td>
              <td>
                <button @click="toggleNotified(alert)" :class="['status-btn', alert.notified ? 'active' : 'inactive']">
                  {{ alert.notified ? 'Yes' : 'No' }}
                </button>
              </td>
              <td>
                <button @click="toggleActive(alert)" :class="['status-btn', alert.active ? 'active' : 'inactive']">
                  {{ alert.active ? 'Yes' : 'No' }}
                </button>
              </td>
              <td>
                <button @click="deleteAlert(alert.id)" class="btn btn-outline-danger btn-sm">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

 <div class="alert-history card">
        <h2>Alert History</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Location</th>
              <th>Type</th>
              <th>Message</th>
              <th>Intensity</th>
              <th>Status</th>
              <th>Duration(s)</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="history in alertHistories" :key="history.id">
              <td>{{ history.location.name }}</td>
              <td>{{ history.notification_type }}</td>
              <td>{{ history.message }}</td>
              <td>{{ history.intensity }}</td>
              <td>{{ history.call_status || 'N/A' }}</td>
              <td>{{ history.call_duration || 'N/A' }}</td>
              <td>{{ new Date(history.created_at).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</template>

<script>
import axios from '@/axios';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'RainAlertDashboard',
  data() {
    return {
      locations: [],
      newAlert: {
        userName: '',
        location: null,
        mobile: '',
        notificationType: 'Call',
        active: true
      },
      alerts: [],
      alertHistories: []
    };
  },
  methods: {
    async fetchLocations() {
      try {
        const response = await axios.get('/locations');
        this.locations = response.data;
      } catch (error) {
        console.error('There was an error fetching locations:', error);
      }
    },
    async fetchAlerts() {
      try {
        const response = await axios.get('/notifications');
        this.alerts = response.data;
      } catch (error) {
        const toast = useToast();
        toast.error('There was an error fetching alerts');
        console.error('There was an error fetching alerts:', error);
      }
    },

    async fetchAlertHistories() {
      try {
        const response = await axios.get('/alert_histories');
        this.alertHistories = response.data;
      } catch (error) {
        console.error('There was an error fetching alert histories:', error);
      }
    },
    async toggleNotified(alert) {
      const toast = useToast();
      try {
        await axios.patch(`/notifications/${alert.id}`, {
          notified: !alert.notified
        });
        alert.notified = !alert.notified;
        toast.success('Notified status updated successfully!');
      } catch (error) {
        toast.error('Failed to update Notified status.');
        console.error('Error updating Notified status:', error);
      }
    },
    async toggleActive(alert) {
      const toast = useToast();
      try {
        await axios.patch(`/notifications/${alert.id}`, {
          active: !alert.active
        });
        alert.active = !alert.active;
        toast.success('Active status updated successfully!');
      } catch (error) {
        toast.error('Failed to update Active status.');
        console.error('Error updating Active status:', error);
      }
    },
    async setNewAlert() {
      const toast = useToast();
      if (this.newAlert.mobile.length !== 10 || isNaN(this.newAlert.mobile)) {
        toast.error('Please enter a valid 10-digit mobile number.');
        return;
      }

      try {
        const response = await axios.post('/notifications', {
          notification: {
            user_name: this.newAlert.userName,
            location: this.newAlert.location.name,
            mobile: this.newAlert.mobile,
            notification_type: this.newAlert.notificationType,
            active: this.newAlert.active
          }
        });

        this.alerts.push(response.data);
        this.newAlert.userName = '';
        this.newAlert.location = null;
        this.newAlert.mobile = '';
        this.newAlert.notificationType = 'Call';
        this.newAlert.active = true;
        toast.success('Alert set successfully!');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          toast.error(error.response.data.errors.join(', '));
        } else {
          toast.error('There was an error setting the alert.');
        }
        console.error('There was an error setting the alert:', error);
      }
    },
    async deleteAlert(id) {
      const toast = useToast();
      try {
        await axios.delete(`/notifications/${id}`);
        this.alerts = this.alerts.filter(alert => alert.id !== id);
        toast.success('Alert deleted successfully!');
      } catch (error) {
        toast.error('There was an error deleting the alert.');
        console.error('There was an error deleting the alert:', error);
      }
    }
  },
  mounted() {
    this.fetchLocations();
    this.fetchAlerts();
    this.fetchAlertHistories();
  }
};
</script>

<style>
.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.alert-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.btn {
  width: 100%;
}

.alert-history {
  margin-top: 20px;
}

.table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}
.status-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.status-btn.active {
  background-color: #28a745;
  color: white;
}

.status-btn.inactive {
  background-color: #6c757d;
  color: white;
}

.btn-outline-danger {
  border: 1px solid #dc3545;
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  color: white;
}

</style>
