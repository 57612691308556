<template>
  <div class="home-container">
    <div class="jumbotron text-center bg-light shadow-sm">
      <h1 class="display-4">Welcome to the Rain Alert System</h1>
      <p class="lead">Stay informed about rainfall predictions in your area with our advanced alerting system.</p>
      <router-link class="btn btn-primary btn-lg mt-3" to="/">Get Started</router-link>
    </div>

    <div class="features-section mt-5">
      <h2 class="text-center">Our Features</h2>
      <div class="row mt-4">
        <div class="col-md-4 mb-4">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title">Real-time Alerts</h5>
              <p class="card-text">Receive real-time notifications about rainfall in your specified locations via SMS or WhatsApp.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title">Accurate Predictions</h5>
              <p class="card-text">Our system uses advanced radar and Doppler data to provide accurate rainfall predictions.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <h5 class="card-title">Easy Setup</h5>
              <p class="card-text">Quickly set up your alerting system by registering and uploading a location image.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-section mt-5">
      <h2 class="text-center">About the Rain Alert System</h2>
      <p class="text-center">The Rain Alert System is designed to keep you informed about rainfall in your area. With real-time alerts and accurate predictions, you can stay prepared and safe. Our easy setup process ensures that you can start receiving alerts quickly and effortlessly.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style>
.home-container {
  padding: 20px;
}

.jumbotron {
  background-color: #f8f9fa;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.features-section {
  padding: 20px;
}

.about-section {
  padding: 20px;
}

.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.card-body {
  padding: 1.5rem;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .features-section .row {
    flex-direction: column;
  }

  .features-section .col-md-4 {
    width: 100%;
    margin-bottom: 20px;
  }

  .features-section .card-body,
  .about-section p {
    text-align: center;
  }
}
</style>
