<template>
  <div class="auth-container">
    <div class="card mb-4 shadow-sm">
      <div class="card-body">
        <h2>Signup</h2>
        <form @submit.prevent="signup">
          <input v-model="fullName" type="text" placeholder="Full Name" class="form-control mb-2" required />
          <input v-model="email" type="email" placeholder="Email" class="form-control mb-2" required />
          <input v-model="mobileNumber" type="text" placeholder="Mobile Number" class="form-control mb-2" required />
          <input v-model="password" type="password" placeholder="Password" class="form-control mb-2" required />
          <input v-model="confirmPassword" type="password" placeholder="Confirm Password" class="form-control mb-2" required />
          <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="loading"> Signing up...</span>
            <span v-else>Signup</span>
          </button>
        </form>
        <div class="mt-3 text-center">
          <router-link to="/signin">Already have an account? Signin</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'SignupPage',
  data() {
    return {
      fullName: '',
      email: '',
      mobileNumber: '',
      password: '',
      confirmPassword: '',
      loading: false,
    };
  },
  methods: {
    async signup() {
      const toast = useToast();
      if (this.password !== this.confirmPassword) {
        toast.error('Passwords do not match', { position: 'top-right' });
        return;
      }

      if (!/^\d{10}$/.test(this.mobileNumber)) {
        toast.error('Mobile number must be 10 digits', { position: 'top-right' });
        return;
      }

      this.loading = true;

      const authStore = useAuthStore();
      try {
        const response = await this.$axios.post('/users', {
          user: {
            full_name: this.fullName,
            email: this.email,
            mobile_number: this.mobileNumber,
            password: this.password,
            password_confirmation: this.confirmPassword,
          },
        });
        const token = response.data.token;
        authStore.setAuthToken(token);
        toast.success('Signup successful', { position: 'top-right' });
        this.$router.push('/dashboard');
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data.errors) {
          error.response.data.errors.forEach(err => {
            toast.error(err, { position: 'top-right' });
          });
        } else {
          toast.error('Signup failed', { position: 'top-right' }    );
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.card-body {
  padding: 1.5rem;
}

.spinner-border {
  margin-right: 0.5rem;
}
</style>
