<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
      <div class="container">
        <router-link class="navbar-brand" to="/">Rain Alert System</router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to="isLoggedIn ? 'dashboard' : '/'" @click="collapseNavbar">Home</router-link>
            </li>
            <li class="nav-item" v-if="!isLoggedIn">
              <router-link class="nav-link" to="/signup" @click="collapseNavbar">Signup</router-link>
            </li>
            <li class="nav-item" v-if="!isLoggedIn">
              <router-link class="nav-link" to="/signin" @click="collapseNavbar">Signin</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <a class="nav-link" href="#" @click="logout">Logout</a>
            </li>
             <li class="nav-item"  v-if="isLoggedIn">
              <router-link class="nav-link" to="/add-location">Add Location</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="content container mt-5">
      <router-view />
    </div>
    <footer class="footer bg-light text-center py-4 mt-5">
      <div class="container">
        <span>&copy; 2024 Rain Alert System. All rights reserved.</span>
      </div>
    </footer>
  </div>
</template>

<script>
import { useAuthStore } from './store/auth';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'App',
  computed: {
    ...mapState(useAuthStore, ['isLoggedIn']),
  },
  methods: {
    ...mapActions(useAuthStore, ['clearAuthToken']),
    collapseNavbar() {
      const navbar = document.getElementById('navbarNav');
      if (navbar.classList.contains('show')) {
        navbar.classList.remove('show');
      }
    },
    logout() {
      this.clearAuthToken();
      this.$router.push('/signin');
    }
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar {
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-link {
  margin-left: 1rem;
  font-size: 1rem;
}

.content {
  margin-top: 2rem;
}

.footer {
  background-color: #f8f9fa;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
  }

  .navbar-nav .nav-item {
    margin-bottom: 10px;
  }
}
</style>
