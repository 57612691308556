import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import SignupPage from '../components/auth/SignupPage.vue';
import SigninPage from '../components/auth/SigninPage.vue';
import DashboardPage from '../components/Dashboard/Index.vue';
import AddLocation from '@/components/AddLocation/Index.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/signin',
    name: 'SigninPage',
    component: SigninPage
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: DashboardPage
  },
  { path: '/add-location', name: 'AddLocation', component: AddLocation }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
